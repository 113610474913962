import React, {useState, useEffect, memo} from 'react';
import Image from 'next/image';

import styles from '@styles/common/SelectDropdown.module.scss';

const wrapperRegister = React.createRef();
const CountryInput = props => {
	const [state, setState] = useState({
		show: false,
		selected: null,
	});
	useEffect(() => {
		const outSideModalClickEvent = event => {
			if (wrapperRegister && !wrapperRegister.current?.contains(event.target)) {
				setState(prev => ({...prev, show: false}));
			}
		};

		document.addEventListener('mousedown', outSideModalClickEvent);
		return () => {
			document.removeEventListener('mousedown', outSideModalClickEvent);
		};
	}, []);
	useEffect(() => {
		if (props.options && props.options?.length) {
			if (props.value) {
				setState(prev => ({...prev, selected: props.value}));
			} else {
				setState(prev => ({...prev, selected: props.options[0]}));
			}
		}

		return () => {
			setState({show: false, selected: null});
		};
	}, [props]);

	const onClick = (event, data) => {
		setState(prev => ({...prev, show: prev.state, selected: data}));
		if (props.onClick) props.onClick(event, data);
	};
	return (
		<div
			className={`${styles.formGroup} ${styles.customCountryGroup} form-group `}
		>
			<div className={styles.selectCountryCustom} ref={wrapperRegister}>
				<div className={styles.chooseCountry}>
					{state.selected && state.selected.src && (
						<div
							className={styles.flagBlock}
							onClick={() => setState(prev => ({...prev, show: !prev.show}))}
						>
							<Image
								width={74}
								height={37}
								alt='ausimg'
								objectFit='fill'
								src={state.selected.src}
							/>

							<span className={styles.label}>
								{props.getValue(state.selected)}
							</span>
						</div>
					)}
					{state.show ? (
						<i
							className='fa fa-chevron-up'
							aria-hidden='true'
							onClick={() => setState(prev => ({...prev, show: false}))}
						></i>
					) : (
						<i
							className='fa fa-chevron-down'
							aria-hidden='true'
							onClick={() => setState(prev => ({...prev, show: true}))}
						></i>
					)}
				</div>
				{state.show && (
					<ul className={styles.selectCountry}>
						{props.options &&
							props.options.length &&
							props.options.map((op, i) => (
								<li key={i} onClick={e => onClick(e, op)}>
									{op.src && (
										<div className={styles.flagBlock}>
											<Image
												layout='fill'
												alt='ausimg'
												src={op.src}
												objectFit='cover'
											/>
										</div>
									)}
									{props.getValue(op)}
								</li>
							))}
					</ul>
				)}
			</div>
		</div>
	);
};
export default memo(CountryInput);
