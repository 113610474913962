import React, {useCallback, useEffect, useState} from 'react';
import Cookies from 'js-cookie';

import {useApp} from '@context/AppProvider';
import SelectDropdown from '@components/common/SelectDropdown';
import styles from '@styles/common/CountrySelection.module.scss';
import Loading from '@components/Loading';

const CountrySelection = ({label = null}) => {
	const {
		fetchSession,
		idb: {currencyData, isoCode},
		showConvertCurrency,
	} = useApp();

	const [cur, setCur] = useState('');
	const [loading, setloading] = useState(false);

	const switchCurrency = useCallback(
		(event, data) => {
			let value = data.isoCode.toLowerCase();
			setCur(value);
			setloading(true);
			Cookies.set('cur', data.currency.toLowerCase());
			Cookies.set('isoCode', data.isoCode);
			fetchSession(true, data.isoCode);
		},
		[fetchSession],
	);

	useEffect(() => {
		setCur(isoCode);
	}, [isoCode]);

	return (
		<>
			{loading && <Loading />}
			{showConvertCurrency() && (
				<div className={styles.searchBox}>
					<span
						className='d-inline-flex align-items-center px-auto'
						style={{paddingLeft: '70px'}}
					>
						{label ?? label}
						<SelectDropdown
							options={currencyData}
							getValue={data => data.name.toLowerCase()}
							onClick={switchCurrency}
							value={
								currencyData.filter(
									mp => mp.isoCode.toLowerCase() === cur.toLowerCase(),
								)?.[0]
							}
						/>
					</span>
				</div>
			)}
		</>
	);
};

export default CountrySelection;
